@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.createListingLink,
.resetAllFiltersButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  display: inline;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
  }
}
.noSearchResultButton{
  width: 100%;
  padding: 20px;
  color: #FFF;
  background-color: #ff5a5f;

  & a{
    color: #FFF;
    border: none;
    &:hover{
      background-color: transparent;
      border: solid 1px var(--marketplaceColor) !important;
    }
  }

  & a:hover{
    background-color:transparent;
    color: #FFF;
    text-decoration: none;
    border: none !important;
  }
}
