@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;

  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
    margin-top: 25px;
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width:340px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 20px 24px 0;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }
  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 0px;
  }

  @media (--viewportLarge) {
    padding: 16px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    margin-top: 28px;
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.esianUsersImg {
  height: 317px;
  width: 100%;
  margin-top: 85px;
}

.esiansText {
  color: #FFF;
  text-align: center;
  margin-top: -144px;
  font-size: 35px;
  font-weight: 600px !important;
}

.adressSearchBar {
  
    display: flex;
    gap: 10px;
    width: 135.5%;
    margin-top: 20px;
    padding-left: 0;
    /* align-items: flex-start; */
    margin: auto;
    /* margin-top: 25px; */
    background: #fcfcfc;
    flex-direction: column;
    padding: 6px 16px 16px 16px;
    /* box-shadow: #00000014 1px 0px 8px 0px; */
    /* border: #eaeaea solid 1px; */

}
.adressSearchBar .innerdiv{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.resetAllButtonMobile {
  width: 148px;
  height: 40px;
  min-height: 35px;
  margin-left: 8px;
  margin-right: -20px;
  margin-top: 0px;
  & span{
      font-size: 12px;
      font-weight: 450;
  }
  @media (--viewportMedium) {
    margin-top: -6px;
    margin-left: 0px;
  }
}

.sliderWrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.applyButton {
  width: 78px;
  height: 38px;
  min-height: 35px;

  @media (--viewportMedium) {
    width: 78px;
    height: 38px;
    min-height: 35px;
  }
}
 .radiusSlider {
 flex-grow: 2;
}
.w50{
display: flex;
gap: 20px;
align-items: center;
}
.searchInMobileView{
  max-height: 43px;
  background: #ffffff;
  border: #3a5795 solid 1px;
  border-radius: 45px;
  width: 260px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-around;
  }
}
.showHideMap{
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 2;
  justify-content: flex-end;
}
.filterSection{
  display: flex;
  gap: 5px;
  align-items: center;
  width: 96%;
}
.filterSectionMap{
  display: flex;
  gap: 5px;
  width: 233px;
  align-items: center;
  justify-content: flex-end;
}

.filterSectionListingPage{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 93%;
  margin: auto;
  margin-top: 12px;
  @media (--viewportMedium) { 
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.searchRadiusSection{
  display: flex;
  justify-content: space-between;
  width: 93%;
  margin: auto;
  margin-top: 12px;
  align-items: center;
    gap: 20px;
}
.sliderradius{
  flex-grow: 3;
  @media (--viewportMedium) {    
  margin: 0 20px;
  }      
}

.searchPagePrice {
  display: flex;
  /* width: 67px; */
  margin-left: 0px;
  margin-top: 0px;

  @media (--viewportMedium) {
    margin-left: 22px;
  }
}

.radiusSliderInSearchPage{
  display: flex;
  padding: 0px 14px;
  flex-direction: column;
  @media (--viewportMedium) {
    padding: 0 32px 0px 16px;
    flex-direction: row;
  }
}

.serchRadiusSection{
  display: flex;
    align-items: center;
    gap: 10px;
}