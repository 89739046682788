@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  /* background-color: var(--colorGrey50); */
  position: relative;
  /* height: 85px;
  margin-bottom: 35px; */
}

.filters {
 

  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
    
  }
}
.buttonHeight {
  width: 100px;
  min-height: 35px;
}

.sliderWrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.applyButton {
  width: 78px;
  height: 38px;
  min-height: 35px;
  margin-top: 14px;
}
 .radiusSlider {
 flex-grow: 2;
}